import type { Article } from "../../../../types/content";

import {
  getAdId,
  getApnTag,
  getGenericExternalId,
  getLayout,
  getSupplyType,
  sanitizeValue,
  windowKeyWords,
} from ".";
import { getClientCookie } from "../../../../utils/getClientCookie";

interface GetKeywordsProps {
  user: { isLoggedIn: boolean; isPremium: boolean };
  topicName?: string;
  geoLocationTracking?: boolean;
  article?: Article;
  consentToAll: boolean | null;
}

type Keywords = Record<string, string | number | string[] | undefined>;

const getKeywords: (
  getKeywordProps: GetKeywordsProps,
) => Promise<Keywords> = async ({
  user,
  topicName,
  geoLocationTracking,
  article,
  consentToAll,
}) => {
  const ads_publisher =
    process.env.NEXT_PUBLIC_UI_THEME === "core" ? "omni" : "omniekonomi";

  const override =
    process.env.NEXT_PUBLIC_UI_THEME === "core"
      ? {}
      : {
          "se-omni-section": "Ekonomi",
          "se-generic-section": "Ekonomi",
        };

  const [apntag, adId] = await Promise.all([getApnTag(), getAdId()]);

  const articleSpecific =
    article && Object.keys(article).length > 0 && article.constructor === Object
      ? {
          "se-generic-page": "article",
          "se-generic-articleid": article.article_id,
          "se-generic-story": sanitizeValue(
            article.story ? article.story.title : undefined,
          ),
          "se-generic-tags": article.tags.map((tag) =>
            sanitizeValue(tag.title),
          ),
          "se-generic-topic": sanitizeValue(article.title.value),
          "se-omni-section": sanitizeValue(article.category.title),
          "se-generic-section": sanitizeValue(article.category.title),
          ...(article.ad_keywords || {}),
          ...override,
        }
      : {};

  const geoLocationSpecific = geoLocationTracking
    ? {
        "se-generic-audience": (getClientCookie("nPsegs") || "").split("|"),
      }
    : {};

  const topicSpecific = topicName
    ? {
        "se-generic-topic": sanitizeValue(topicName),
      }
    : {};

  return {
    "se-generic-sdk-version":
      apntag && typeof apntag.getAstVersion === "function"
        ? apntag.getAstVersion()
        : "",
    "se-generic-page": "index",
    "aa-sch-country_code": "se",
    "aa-sch-publisher": `${ads_publisher}`,
    "aa-sch-supply_type": `${getSupplyType()}`,
    "aa-sch-cmp_advertising": consentToAll ? "1" : "0",
    "se-generic-appmode": "notinapp",
    "se-generic-pagegen": "smp",
    "se-generic-loggedin": `${user?.isLoggedIn}`,
    "se-generic-premium": `${user?.isPremium}`,
    "se-generic-sitemode": getLayout(),
    "se-generic-screenwidth": window.outerWidth,
    "se-generic-screenheight": window.outerHeight,
    "se-generic-viewportwidth": window.innerWidth,
    "se-generic-viewportheight": window.innerHeight,
    "se-generic-externalid_src": getGenericExternalId({
      adId,
      loggedIn: user.isLoggedIn,
    }),
    ...geoLocationSpecific,
    ...articleSpecific,
    ...windowKeyWords(),
    ...topicSpecific,
  };
};

export { getKeywords };

export type { Keywords };
