import Image from "next/image";

import MarkusCore from "../../../../../assets/markus-core.png";
import MarkusEko from "../../../../../assets/markus-eko.png";
import styles from "./ExplainerMessage.module.scss";

const brandName = process.env.NEXT_PUBLIC_UI_THEME;
const isEconomy = brandName === "eko";

const ExplainerMessage = () => (
  <section className={styles.container}>
    <div className={styles.messageFlex}>
      <p className={styles.message}>
        <span className={styles.bold}>Hej!</span> Du ser denna annons eftersom
        Omni till stor del finansieras av annonsintäkter. Tack för att du
        stödjer vårt journalistiska arbete.
      </p>
      <div className={styles.iconContainer}>
        <Image
          src={isEconomy ? MarkusEko : MarkusCore}
          alt="Editor in chief"
          width={100}
          height={100}
        />
      </div>
    </div>
    <p>/Markus Gustafsson, chefredaktör</p>
  </section>
);

export { ExplainerMessage };
