import { Heading } from "@ariakit/react";

import type { MouseEventHandler } from "react";

import { Icon } from "../../../Icon/Icon";
import styles from "./Header.module.scss";

const brandName =
  process.env.NEXT_PUBLIC_UI_THEME === "core" ? "Omni" : "Omni Ekonomi ";

const Header = ({ close }: { close: MouseEventHandler<HTMLElement> }) => {
  return (
    <section className={styles.container}>
      <Heading className={styles.header}>Annonser i {brandName}</Heading>
      <button className={styles.close} onClick={(e) => close(e)}>
        <Icon variant="close" size="small" />
      </button>
    </section>
  );
};

export { Header };
