import type {
  AdInformation,
  ConfigAndLoadAdPlacement,
  AstAdObj,
  AstAdError,
} from "@omni/ads";

import {
  getAdId,
  getApnTag,
  getPPIDWithTagValues,
  isNativeAd,
  isNativeBanner,
  sizeToArray,
} from "./helpers";
import { loadAds } from "./helpers/bidding";
import { getKeywords } from "./helpers/getKeywords";
import { calculateScaleFactor } from "./helpers/calculateScaleFactor";
import { verticalVideoYAxisFixedScaling } from "./constants";

const reloadAd = async (id: string) => {
  const apnTag = await getApnTag();
  apnTag.anq.push(() => apnTag.refresh([id]));
};

// Configure and load ad for specified adRule (placement)
const configureAndLoadAdPlacement = async ({
  id,
  adRule,
  user,
  article,
  topicName,
  consentToAll,
  marketingConsent,
  geoTracking,
  onLoad,
  onAvailable,
  onFailure,
  notAvailable,
}: ConfigAndLoadAdPlacement): Promise<AdInformation> => {
  const [apntag, adId, [ppIds, ppIdsTags], keywords] = await Promise.all([
    getApnTag(),
    getAdId(),
    getPPIDWithTagValues(),
    getKeywords({
      geoLocationTracking: geoTracking,
      user,
      topicName,
      article,
      consentToAll,
    }),
  ]);

  return new Promise((resolve, reject) => {
    if (!adRule.appnexus_data) {
      return;
    }

    try {
      const tag = {
        invCode: adRule.appnexus_data.inventory_code,
        sizes: adRule.appnexus_data.sizes.map(sizeToArray),
        targetId: id,
        enableSafeFrame: true,
        setSafeFrameConfig: {
          allowExpansionByPush: true,
          allowExpansionByOverlay: true,
        },
        allowedFormats: adRule.appnexus_data.allowed_formats,
        keywords: {
          "se-generic-adformat":
            adRule.appnexus_data?.ad_formats &&
            adRule.appnexus_data.ad_formats.length > 0
              ? adRule.appnexus_data.ad_formats
              : [],
        },
      };

      apntag.anq.push(async () => {
        apntag.setPageOpts({
          member: 9943,
          keywords: {
            ...keywords,
            ...ppIdsTags,
          },
          user: {
            externalUid: marketingConsent || consentToAll ? undefined : adId,
            userIds: [{ type: "extendedIDs", eids: ppIds }],
          },
        });

        apntag.onEvent("adNoBid", id, (e: AstAdObj) => {
          if (notAvailable) {
            notAvailable();
          }
          reject(e);
        });
        apntag.onEvent("adRequestFailure", id, (e: AstAdError) => {
          if (onFailure) {
            onFailure();
          }
          reject(e);
        });
        apntag.onEvent("adError", id, (e: AstAdError, obj: AstAdObj) => {
          if (onFailure) {
            onFailure();
          }
          reject(e);
        });
        apntag.onEvent("adBadRequest", id, (e: AstAdError) => {
          if (onFailure) {
            onFailure();
          }
          reject(e);
        });
        apntag.onEvent("adAvailable", id, (e: AstAdObj) => {
          if (
            e.targetId?.includes("fullscreen") ||
            e.targetId?.includes("takeover")
          ) {
            onAvailable(e);
          }
        });

        apntag.onEvent("adLoaded", id, (e: AstAdObj) => {
          if (isNativeAd(e)) {
            apntag.fireImpressionTrackers(e);
            onLoad(e);
          }

          if (isNativeBanner(e)) {
            const subContainer = document.querySelector(
              `#div_utif_${id}`,
            ) as HTMLDivElement;
            const ad = e;

            const initalAdWidth = ad?.width <= 5 ? "100%" : `${ad?.width}`;

            const initalAdHeight = ad?.height <= 5 ? "100%" : `${ad?.height}`;

            // FSS === fullscreen scroll
            const isRegularFSS = id.includes("fullscreen");

            const isVideoFSS =
              isRegularFSS &&
              initalAdHeight === "1920" &&
              initalAdWidth === "1080";

            const isFSS = isVideoFSS || isRegularFSS;

            const FSSWidth = "100%";
            const FSSHeigh = isVideoFSS
              ? `${verticalVideoYAxisFixedScaling.height}px`
              : "100vh";

            if (subContainer) {
              subContainer.style.width = isFSS ? FSSWidth : initalAdWidth;
              subContainer.style.height = isFSS ? FSSHeigh : "100%";

              if (isVideoFSS) {
                const { scaleX } = calculateScaleFactor(e);
                subContainer.style.transform = `scale(${scaleX}, ${verticalVideoYAxisFixedScaling.scaleFactor})`;
                subContainer.style.transformOrigin = "left top";
              }

              const iframe = subContainer.querySelector("iframe");
              if (iframe) {
                iframe.style.width =
                  isRegularFSS && !isVideoFSS ? FSSWidth : initalAdWidth;

                if (!id?.includes("takeover")) {
                  iframe.style.height =
                    isRegularFSS && !isVideoFSS ? FSSHeigh : initalAdHeight;
                }

                iframe.title = "Xandr ad";
              }
            }

            const adWidth = isFSS ? FSSWidth : initalAdWidth;
            const adHeight = isFSS ? (isVideoFSS ? "100%" : FSSHeigh) : "100%";

            resolve({
              stylesFromXandr: {
                width: adWidth,
                height: adHeight,
              },
              creativeId: e.creativeId?.toString(),
              isNativeBanner: true,
            });
          }

          resolve({
            stylesFromXandr: {},
            isNativeBanner: false,
          });
        });

        apntag.defineTag(tag);

        loadAds([id], keywords).then(() => {
          apntag.showTag(id);
        });
      });
    } catch (e) {
      console.error("appnexus errored during apntag work", e);
      throw e;
    }
  });
};

export { configureAndLoadAdPlacement, reloadAd };
