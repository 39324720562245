import { Heading } from "@ariakit/react";
import cn from "classnames";

import styles from "./Cta.module.scss";
import { Button } from "../../../Button/Button";

const isEconomy = process.env.NEXT_PUBLIC_UI_THEME === "ekonomi";
const brandName: "omni" | "ekonomi" = isEconomy ? "ekonomi" : "omni";

const configs = {
  omni: {
    header: "Skaffa Omni Mer!",
    text: "Med Omni Mer får du tillgång till all vår fördjupande och förklarande journalistik. Dessutom blir då Omni annonsfri.",
    buttonText: "Bli medlem i Omni Mer",
    buttonLink: "https://om.omni.se/omni-mer",
  },
  ekonomi: {
    header: "Uppgradera din prenumeration!",
    text: "Om du uppgraderar din prenumeration så kan du läsa Omni Ekonomi helt utan annonser. Dessutom får du ett extra konto att dela med vem du vill.",
    buttonText: "Uppgradera!",
    buttonLink: "https://om.omni.se/ekonomi",
  },
};

const Cta = () => (
  <section className={styles.container}>
    <Heading className={styles.header}>
      Vill du slippa annonser helt? <br />
      {configs[brandName].header}
    </Heading>
    <p className={styles.text}>{configs[brandName].text}</p>
    <Button
      type="link"
      isExternalLink
      href={configs[brandName].buttonLink}
      className={cn([
        styles.button,
        isEconomy ? styles.economyButton : styles.coreButton,
      ])}
      text={configs[brandName].buttonText}
    />
  </section>
);

export { Cta };
