import { Heading } from "@ariakit/react";
import cn from "classnames";

import styles from "./GeneralAboutAdsInSchibsted.module.scss";
import { SectionSeparator } from "./SectionSeparator";

const GeneralAboutAdsInSchibsted = ({
  privacyUrl,
  creativeId,
}: {
  privacyUrl: string;
  creativeId: string;
}) => {
  return (
    <>
      <section className={styles.section}>
        <Heading className={styles.header}>
          Varför ser du just denna annons?
        </Heading>
        <p className={styles.text}>
          Vissa annonser ser du för att vi tror att de kan intressera dig. Detta
          antagande baserar vi på din tidigare aktivitet på Schibsteds sajter
          och appar.
        </p>
        <p className={styles.text}>
          Vi samarbetar också med vissa annonsörer om annonsprodukten Schibsted
          Match. Inom ramarna för Schibsted Match delar annonsören antingen
          kundernas e-postadress eller telefonnummer med Schibsted för att bygga
          en anpassad målgrupp. Under den processen skickar Schibsted inte
          användardata till annonsören.
        </p>

        <a
          href="https://info.privacy.schibsted.com/se/gemensamt-personuppgiftsansvar-inom-ramen-for-schibsted-match/"
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          Läs mer om våra Schibsted Match-annonsörer.
        </a>
      </section>
      <SectionSeparator />
      <section className={styles.section}>
        <Heading className={styles.header}>
          Hantera dina annonsinställningar
        </Heading>

        <p className={styles.text}>
          Du kan ge ditt samtycke eller neka behandling av dina aktivitetsdata
          för anpassad annonsering via cookieinställningarna som du hittar på
          webbplatsen du besöker eller i inställningarna för appen du använder.
        </p>
        <p className={styles.text}>
          För alla andra typer av data, såsom kontoinformation kopplad till ditt
          Schibsted-konto, kan du uppdatera dina val för personanpassade
          annonser i sekretessinställningarna.
        </p>

        <a
          href={privacyUrl}
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          Uppdatera dina val för personanpassade annonser
        </a>
      </section>
      <SectionSeparator />
      <section className={styles.section}>
        <Heading className={styles.header}>
          Om Schibsted Media och annonsering
        </Heading>
        <p className={styles.text}>
          Omni är en del av{" "}
          <a
            href="https://schibsted.com/"
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            Schibsted Media
          </a>
        </p>

        <a
          href="https://info.privacy.schibsted.com/se/s005"
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          Läs mer om annonsering på Schibsted Media
        </a>
      </section>
      <SectionSeparator />
      <section className={styles.section}>
        <Heading className={styles.header}>Problem med annonsen?</Heading>
        <p className={styles.text}>
          Om du upplever problem med den här annonsen kan du kontakta vår
          kundsupport. Ange id-numret nedan.
        </p>

        <div className={styles.creativeIdContainer}>
          <p className={cn([styles.text, styles.creativeId])}>
            Annonsens ID-nummer: <span>{creativeId}</span>
          </p>
        </div>
      </section>
    </>
  );
};

export { GeneralAboutAdsInSchibsted };
