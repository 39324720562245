import { HeadingLevel } from "@ariakit/react";

import type { AdInfoProps } from "../types";

import styles from "./Container.module.scss";
import { Header } from "./Header";
import { ExplainerMessage } from "./ExplainerMessage";
import { SectionSeparator } from "./SectionSeparator";
import { Cta } from "./Cta";
import { GeneralAboutAdsInSchibsted } from "./GeneralAboutAdsInSchibsted";

const AdInfoModal = ({
  creativeId,
  privacyUrl,
  closeModal,
  modalRef,
}: AdInfoProps) => (
  <section tabIndex={0} ref={modalRef} className={styles.container}>
    <HeadingLevel>
      <Header close={closeModal} />
      <section className={styles.contentArea}>
        <HeadingLevel>
          <ExplainerMessage />
          <SectionSeparator />
          <Cta />
          <SectionSeparator />
          <GeneralAboutAdsInSchibsted
            privacyUrl={privacyUrl}
            creativeId={creativeId}
          />
        </HeadingLevel>
      </section>
    </HeadingLevel>
  </section>
);

export { AdInfoModal };
